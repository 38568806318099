export default function ListaVoluntari() {
  return (
    <>
      <h1>Dragut Stefan Constantin - xStefanut21</h1>
      <h1>Dulgheriu Bogdan Gabriel - dulgheriubogdan</h1>
      <h1>Calin Silvian Petre - xRankk</h1>
      <h1>Cazan Florin-Ciprian - Ciprau</h1>
      <h1>Cristi Miloiu - cristim67</h1>
      <h1>Stefan Ionel - smiromania</h1>
      <h1>Georgescu Andrei - GeorgescuAndrei</h1>
      <h1>Bogdan-George Boiangiu - kxppy</h1>
      <h1>Mogda Ionut-Liviu - mogd-a</h1>
      <h1>Cernat Alexandru Florin - iWar10</h1>
      <h1>Condrat Ioan-Andrei - Aciam005</h1>
      <h1>Robert Stancu - Robykus</h1>
      <h1>Georgescu Alexandru Gabriel - AlexGG27</h1>
      <h1>Andronie Vasile Laurentiu - Laur14</h1>
      <h1>Andrei Ciuhureanu - AndreiCiuhu</h1>
      <h1>Vlad Panait - vladex11</h1>
      <h1>Moisanu David-Matheo - moisanumatheo</h1>
      <h1>Grecu Victor - IonGigi970</h1>
      <h1>Cezar - cezar1409</h1>
      <h1>Ochenatu Andrei - Drakonul</h1>
      <h1>Negru Alexandru - TheBlackMan2004</h1>
      <h1>Dragos Popirtac - d0ghe</h1>
      <h1>Salafenco Cristian - CristiSx</h1>
      <h1>Drug Razvan Nicolae - Razy112 </h1>
      <h1>Mirea Vlad-Cristian - Vlqdxd</h1>
      <h1>Mihnea Aniculesei - amihneaa</h1>
      <h1>Muraru-Alexa Daria-Elena - DariaM9 </h1>
      <h1>Grigorescu Dan - danydanyqwer</h1>
      <h1>Ivan Andrei - ivannandrei</h1>
      <h1>Nita Abid - Apizduk </h1>
      <h1>Micle Beniamin - Benny-Jr</h1>
      <h1>Malacescu Gabriel Tiberiu - NameIsGabe</h1>
      <h1>Tanasie Cezar Nicolae - aNThiCu</h1>
      <h1>Guta Andrei - andreitheclone</h1>
    </>
  );
}
